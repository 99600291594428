import { getGatsbyImageData } from "@imgix/gatsby"
import { CmsGuideLayout } from "../../../components/CmsGuideLayout"
import { lorem } from "../../../lib/lorem"
import { PageDataBodyVideo } from "../../../slices/PageDataBodyVideo"

type Props = Partial<React.ComponentPropsWithoutRef<typeof PageDataBodyVideo>>

const videoHTML =
	'<iframe src="https://www.youtube.com/embed/DslTxXsDLsw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'

const Component = (props: Props) => {
	return (
		<CmsGuideLayout>
			<PageDataBodyVideo
				title={lorem.sentences(2)}
				image={getGatsbyImageData({
					width: 900,
					layout: "fullWidth",
					src: "https://images.unsplash.com/photo-1444723121867-7a241cacace9",
					aspectRatio: 2370 / 1580,
				})}
				imageAlt=""
				video={videoHTML}
				{...props}
			/>
		</CmsGuideLayout>
	)
}

export default Component
